import React, {createContext} from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TagsList from "../components/tagslist"
import { createTagsNameMap } from "../components/functions/createconst"
import CreateList from "../components/createlist"

import * as styles from "../styles/tags.module.scss"

export const PostsContext = createContext()

const Tags = ({ pageContext, data, location }) => {
    const tagsnamemap = createTagsNameMap()

    const tag = pageContext.tag
    const { totalCount } = data.allMdx
    const siteTitle = data.site.siteMetadata.title
    const posts = data.posts.nodes

    const tagHeader = `${tagsnamemap[tag]}`

    return (
        <Layout location = {location} title={siteTitle}>
            <div className={styles.bodywrapper}>
                <h1>{tagHeader}</h1>

                <TagsList istop={true}></TagsList>

                <div>
                    {tagHeader}について{totalCount}件の投稿があります
                </div>

                <CreateList posts = { posts }></CreateList>

            </div>
        </Layout>
    )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
    allMdx: PropTypes.shape({
        totalCount: PropTypes.number.isRequired,
        edges: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }),
            }),
        }).isRequired
        ),
    }),
    }),
}

export default Tags

export const pageQuery = graphql`
    query($tag: String) {
        site{
            siteMetadata{
                title
            }
        }
        taggroup: allMdx(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        allMdx(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                    slug
                    }
                    frontmatter {
                        title
                        category1
                        category2
                    }
                }
            }
        }
        posts: allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {frontmatter: {tags: {eq: $tag}}}
        ) {
            nodes {
                id
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    category1
                    category2
                    title
                    description
                    hero{
                        childImageSharp{
                            gatsbyImageData(
                                width: 600
                                placeholder: NONE
                            )
                        }
                    }
                }
            }
        }
    }
`